import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Componentes principales
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import ThreeDViewerPage from "./pages/ThreeDViewerPage";
import NewArrivalDetails from "./components/NewArrivalDetails";

// Componentes administrativos
import AdminPanel from "./components/AdminPanel";
import AddArtwork from "./components/AddArtwork";
import Blog from "./components/Blog";
import AddExperience from "./components/AddExperience";
import Upload3DModel from "./components/Upload3DModel";
import AddNewArrival from "./components/AddNewArrival";

// Detalles
import ArtworkDetail from "./components/ArtworkDetail";
import BlogDetail from "./components/BlogDetail";

// Autenticación
import Login from "./components/Login";

// Popups
import IntroPopup from "./components/IntroPopup";
import FilteredResultsPopup from "./components/FilteredResultsPopup";

// PDF Viewer
import PDFViewer from "./components/PDFViewer";

// Ruta del archivo PDF
const catalogPdf = `${process.env.PUBLIC_URL}/pdf/Catalogocarinafabaro.pdf`;

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Estado de autenticación
  const [isViewerOpen, setIsViewerOpen] = useState(false); // Estado del visor de PDF

  // Estados para manejar popups
  const [showIntroPopup, setShowIntroPopup] = useState(true);
  const [showFilteredPopup, setShowFilteredPopup] = useState(false);

  // Obras y material seleccionado
  const [filteredArtworks, setFilteredArtworks] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  // Manejar autenticación
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });
    return () => unsubscribe();
  }, []);

  // Manejar el cierre del popup de introducción
  const handleFilterComplete = (artworks) => {
    setFilteredArtworks(artworks);
    setShowIntroPopup(false);
    setShowFilteredPopup(true);
  };

  // Manejar el regreso al menú desde el filtro
  const handleBackToMenu = () => {
    setShowFilteredPopup(false);
    setShowIntroPopup(true);
  };

  // Manejar apertura y cierre del visor de PDF
  const openViewer = () => setIsViewerOpen(true);
  const closeViewer = () => setIsViewerOpen(false);

  return (
    <Router>
      {/* Popup de introducción */}
      {showIntroPopup && (
        <IntroPopup
          setArtworks={setFilteredArtworks}
          setSelectedMaterial={setSelectedMaterial}
          onFilterComplete={handleFilterComplete}
          onClose={() => setShowIntroPopup(false)}
        />
      )}

      {/* Popup de resultados filtrados */}
      {showFilteredPopup && (
        <FilteredResultsPopup
          artworks={filteredArtworks}
          onClose={() => setShowFilteredPopup(false)}
          onBackToMenu={handleBackToMenu}
        />
      )}

      {/* Encabezado */}
      <Header isAuthenticated={isAuthenticated} />

      {/* Contenido principal */}
      <main>
        <Routes>
          {/* Rutas públicas */}
          <Route
            path="/"
            element={<Home artworks={filteredArtworks} selectedMaterial={selectedMaterial} />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route
            path="/contact"
            element={<Contact catalogPdf={catalogPdf} openViewer={openViewer} />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/artwork/:id" element={<ArtworkDetail />} />
          <Route path="/new/:id" element={<NewArrivalDetails />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/3d-viewer" element={<ThreeDViewerPage />} />

          {/* Rutas protegidas para administración */}
          <Route
            path="/admin"
            element={
              isAuthenticated ? <AdminPanel /> : <Navigate to="/login" replace />
            }
          />
          <Route
            path="/admin/add-artwork"
            element={
              isAuthenticated ? <AddArtwork /> : <Navigate to="/login" replace />
            }
          />
          <Route
            path="/admin/blog"
            element={
              isAuthenticated ? <Blog /> : <Navigate to="/login" replace />
            }
          />
          <Route
            path="/admin/add-experience"
            element={
              isAuthenticated ? <AddExperience /> : <Navigate to="/login" replace />
            }
          />
          <Route
            path="/admin/upload-3d"
            element={
              isAuthenticated ? <Upload3DModel /> : <Navigate to="/login" replace />
            }
          />
          <Route
            path="/admin/new-arrivals"
            element={
              isAuthenticated ? <AddNewArrival /> : <Navigate to="/login" replace />
            }
          />
        </Routes>

        {/* Visor PDF modal */}
        {isViewerOpen && <PDFViewer pdfFile={catalogPdf} onClose={closeViewer} />}
      </main>

      {/* Pie de página */}
      <Footer isAuthenticated={isAuthenticated} />
    </Router>
  );
};

export default App;
