import React, { useState } from "react";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import FilteredResultsPopup from "./FilteredResultsPopup";
import About from "../pages/About";
import "./IntroPopup.css";

/**
 * Componente de introducción para filtrar por materiales con portada inicial.
 * @param {Function} setSelectedMaterial - Actualiza el material seleccionado.
 * @param {Function} onClose - Función para cerrar el popup e ir a Home.
 */
const IntroPopup = ({ setSelectedMaterial, onClose }) => {
  const [artworks, setArtworks] = useState([]); // Obras filtradas
  const [showFilteredPopup, setShowFilteredPopup] = useState(false); // Estado del popup de resultados
  const [showCoverPopup, setShowCoverPopup] = useState(true); // Estado del popup de portada
  const [isLoading, setIsLoading] = useState(false); // Estado de carga
  const [showIndependentAboutPopup, setShowIndependentAboutPopup] = useState(false); // Estado del popup independiente "Sobre mí"
  const db = getFirestore(); // Inicializa Firestore

  // Manejar la selección de materiales
  const handleMaterialClick = async (materials) => {
    try {
      setIsLoading(true);
      const artworksRef = collection(db, "artworks");
      const materialQuery = query(artworksRef, where("material", "in", materials));
      const querySnapshot = await getDocs(materialQuery);

      const filteredArtworks = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setArtworks(filteredArtworks);
      setSelectedMaterial(materials);
      setShowFilteredPopup(true);
    } catch (error) {
      console.error("Error al obtener obras:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const closeFilteredPopup = () => {
    setShowFilteredPopup(false);
    onClose();
  };

  const closeCoverPopup = () => {
    setShowCoverPopup(false);
  };

  const openIndependentAboutPopup = () => {
    setShowIndependentAboutPopup(true);
  };

  const closeIndependentAboutPopup = () => {
    setShowIndependentAboutPopup(false);
    window.scrollTo(0, 0); // Vuelve al inicio de la página
  };

  return (
    <>
      {showCoverPopup && !showIndependentAboutPopup && (
        <div className="cover-popup">
          <div className="cover-content">
            <img
              src="/images/cf1.jpg"
              alt="Portada del artista"
              className="cover-image"
            />
            <h1 className="artist-name">Carina Fabaro</h1>
            <div className="cover-buttons">
              <button className="about-button" onClick={openIndependentAboutPopup}>Sobre mí</button>
              <button className="menu-button" onClick={closeCoverPopup}>Ir al menú</button>
            </div>
          </div>
        </div>
      )}

      {showIndependentAboutPopup && (
        <div className="independent-about-popup">
          <div className="independent-about-content">
            <About />
            <button className="close-independent-about-button" onClick={closeIndependentAboutPopup}>Cerrar</button>
          </div>
        </div>
      )}

      {!showCoverPopup && !showIndependentAboutPopup && (
        <div className="intro-popup">
          <div className="intro-content">
            <h2 className="intro-title">¿Qué materiales quieres explorar?</h2>
            <div className="wheel-menu">
              <button className="wheel-button" onClick={() => handleMaterialClick(["Metal"])}>Metal</button>
              <button className="wheel-button" onClick={() => handleMaterialClick(["Madera"])}>Madera</button>
              <button className="wheel-button" onClick={() => handleMaterialClick(["Piedra"])}>Piedra</button>
              <button className="wheel-button" onClick={() => handleMaterialClick(["Madera y Piedra"])}>Madera y Piedra</button>
              <button className="wheel-button" onClick={() => handleMaterialClick(["Metal y Piedra"])}>Metal y Piedra</button>
              <button className="wheel-button" onClick={() => handleMaterialClick(["Metal y Madera"])}>Metal y Madera</button>
            </div>
            <button className="skip-button" onClick={onClose}>X</button>
          </div>
        </div>
      )}

      {isLoading && <div className="loading">Cargando resultados...</div>}

      {showFilteredPopup && (
        <FilteredResultsPopup artworks={artworks} onClose={closeFilteredPopup} />
      )}
    </>
  );
};

export default IntroPopup;
