import React, { useState } from "react";
import "./FilteredResultsPopup.css";

/**
 * Componente para mostrar los resultados filtrados en un popup.
 * @param {Array} artworks - Lista de obras filtradas.
 * @param {Function} onClose - Función para cerrar el popup y regresar al Home.
 */
const FilteredResultsPopup = ({ artworks, onClose }) => {
  const [expandedArtwork, setExpandedArtwork] = useState(null); // ID de la obra expandida

  // Manejar la expansión/contracción de información adicional
  const handleExpand = (artworkId) => {
    setExpandedArtwork(expandedArtwork === artworkId ? null : artworkId);
  };

  // Reiniciar la página
  const handleReset = () => {
    window.location.reload();
  };

  return (
    <div className="filtered-popup">
      <div className="filtered-content">
        <h2 className="filtered-title">Resultados del Filtro</h2>
        <div className="artworks-list">
          {artworks && artworks.length > 0 ? (
            artworks.map((artwork) => (
              <div className="artwork-card" key={artwork.id}>
                <img
                  src={artwork.image || "https://via.placeholder.com/150"}
                  alt={artwork.title || "Obra sin título"}
                  className="artwork-image"
                />
                <div className="artwork-info">
                  <h3>{artwork.title || "Sin título"}</h3>
                  <p>{artwork.material || "Material no especificado"}</p>
                  {/* Mostrar información adicional si está expandido */}
                  {expandedArtwork === artwork.id && (
                    <div className="additional-info">
                      <p>
                        <strong>Descripción:</strong> {artwork.description || "No disponible"}
                      </p>
                      <p>
                        <strong>Año:</strong> {artwork.year || "No especificado"}
                      </p>
                    </div>
                  )}
                  <button
                    className="expand-button"
                    onClick={() => handleExpand(artwork.id)}
                  >
                    {expandedArtwork === artwork.id ? "Ver menos" : "Ver más"}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="no-results">No se encontraron resultados.</p>
          )}
        </div>
        <button className="home-button" onClick={onClose}>
          Ir a Home
        </button>
      </div>

      {/* Botón flotante para reiniciar la página */}
      <button
        className="floating-back-button"
        onClick={handleReset}
        style={{
          position: "fixed",
          bottom: "10%",
          right: "5%",
          background: "#007bff",
          color: "white",
          border: "none",
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
          cursor: "pointer",
          zIndex: 1000,
        }}
      >
        Menú
      </button>
    </div>
  );
};

export default FilteredResultsPopup;
