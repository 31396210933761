import React from "react";
import "./tarjeta.css";

const ArtworkCard = ({ artwork }) => (
  <div className="artwork-card">
    <img src={artwork.image} alt={artwork.title} />
    <h3>{artwork.title}</h3>
    <p><strong>Material:</strong> {artwork.material}</p>
    <p><strong>Año:</strong> {artwork.year}</p>
    <p>{artwork.description}</p>
  </div>
);

export default ArtworkCard;
