import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, onSnapshot } from "firebase/firestore";
import "./About.css";

const About = () => {
  const [experiences, setExperiences] = useState([]);

  // Cargar datos de la colección `experiences`
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "experiences"), (snapshot) => {
      const fetchedExperiences = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Ordenar las experiencias por fecha descendente
      const sortedExperiences = fetchedExperiences.sort(
        (a, b) => b.date.seconds - a.date.seconds
      );
      setExperiences(sortedExperiences);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="about container mt-5">
      <h2 className="text-center">Sobre Mí</h2>
      <div className="row align-items-center mt-4">
        {/* Columna de la fotografía */}
        <div className="col-lg-4 col-md-6 text-center mb-4">
          <img
            src="/images/cf3H.jpg"
            alt="Carina Fabaro"
            className="img-fluid"
            style={{ maxWidth: "250px", height: "250px" }}
          />
        </div>
        {/* Columna del texto */}
        <div className="col-lg-8 col-md-6">
          <p className="text-justify">
            Explora mi experiencia y trayectoria profesional en el área artística.
            Mi trabajo abarca diversas técnicas y materiales, buscando siempre
            transmitir emociones y contar historias a través de mis esculturas.
          </p>
        </div>
      </div>

      {/* Lista de Experiencias en formato de tarjetas */}
      <h3 className="mt-5 text-center">Trayectoria Profesional</h3>
      <div className="experience-cards mt-4">
        {experiences.map((exp) => (
          <div className="experience-card" key={exp.id}>
            <h4>{exp.name}</h4>
            <p>
              <strong>Fecha:</strong>{" "}
              {new Date(exp.date.seconds * 1000).toLocaleDateString()}
            </p>
            <p>
              <strong>País:</strong> {exp.country}
            </p>
            <p>
              <strong>Provincia/Estado:</strong> {exp.state}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
