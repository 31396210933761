import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./PDFViewer.css";

// Configurar workerSrc para usar el archivo local
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;

const PDFViewer = ({ pdfFile, onClose }) => {
  const [numPages, setNumPages] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);

  // Manejar la carga exitosa del documento
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setCurrentPage(1);
  };

  // Manejadores de navegación entre páginas
  const goToNextPage = () => {
    if (currentPage < numPages) setCurrentPage((prev) => prev + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  return (
    <div className="pdf-viewer">
      <button onClick={onClose} className="btn btn-danger close-btn">
        Cerrar
      </button>
      <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess} className="pdf-document">
        <Page pageNumber={currentPage} className="pdf-page" />
      </Document>
      <div className="pdf-controls">
        <button
          className="btn btn-secondary"
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        <span className="page-info">
          Página {currentPage} de {numPages}
        </span>
        <button
          className="btn btn-secondary"
          onClick={goToNextPage}
          disabled={currentPage === numPages}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default PDFViewer;
