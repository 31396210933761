import React, { useState, useEffect } from "react";
import { db, storage } from "../firebaseConfig";
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Swal from "sweetalert2";
import "./AddArtwork.css";

const AddArtwork = () => {
  const [artworks, setArtworks] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [material, setMaterial] = useState("");
  const [year, setYear] = useState(""); // Nuevo estado para el año
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [showInHome, setShowInHome] = useState(true); 
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchArtworks();
  }, []);

  // Obtener las obras desde Firestore
  const fetchArtworks = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "artworks"));
      const fetchedArtworks = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setArtworks(fetchedArtworks);
    } catch (error) {
      console.error("Error al obtener obras:", error);
      Swal.fire("Error", "No se pudo obtener la lista de obras.", "error");
    }
  };

  // Subir imagen a Firebase Storage
  const handleImageUpload = async () => {
    if (!image) return null;
    try {
      const imageRef = ref(storage, `artworks/${image.name}`);
      await uploadBytes(imageRef, image);
      return await getDownloadURL(imageRef);
    } catch (error) {
      console.error("Error al subir la imagen:", error);
      Swal.fire("Error", "No se pudo subir la imagen.", "error");
    }
  };

  // Agregar o editar una obra
  const handleAddOrEditArtwork = async () => {
    if (!title || !description || !material || !year || (!image && !editId)) {
      Swal.fire("Error", "Todos los campos son obligatorios.", "error");
      return;
    }

    try {
      let uploadedImageUrl = imageUrl;
      if (image) {
        uploadedImageUrl = await handleImageUpload();
      }

      if (editId) {
        // Editar obra existente
        const artworkRef = doc(db, "artworks", editId);
        await updateDoc(artworkRef, { title, description, material, year, image: uploadedImageUrl, showInHome });
        Swal.fire("Éxito", "Obra actualizada exitosamente.", "success");
      } else {
        // Agregar nueva obra (activada por defecto)
        await addDoc(collection(db, "artworks"), {
          title,
          description,
          material,
          year,
          image: uploadedImageUrl,
          showInHome: true,
        });
        Swal.fire("Éxito", "Obra añadida exitosamente.", "success");
      }

      resetForm();
      fetchArtworks();
    } catch (error) {
      console.error("Error al guardar la obra:", error);
      Swal.fire("Error", "No se pudo guardar la obra.", "error");
    }
  };

  // Eliminar una obra
  const handleDeleteArtwork = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás deshacer esta acción.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteDoc(doc(db, "artworks", id));
          Swal.fire("Eliminada", "La obra ha sido eliminada.", "success");
          fetchArtworks();
        } catch (error) {
          console.error("Error al eliminar la obra:", error);
          Swal.fire("Error", "No se pudo eliminar la obra.", "error");
        }
      }
    });
  };

  // Alternar visualización en Home
  const handleShowInHomeToggle = async (id, currentValue) => {
    try {
      const artworkRef = doc(db, "artworks", id);
      const newValue = !currentValue;

      // Actualizar Firestore
      await updateDoc(artworkRef, { showInHome: newValue });

      // Actualizar la UI
      setArtworks((prev) =>
        prev.map((artwork) =>
          artwork.id === id ? { ...artwork, showInHome: newValue } : artwork
        )
      );

      Swal.fire(
        "Actualizado",
        `La obra ahora está ${newValue ? "visible" : "oculta"} en el Home.`,
        "success"
      );
    } catch (error) {
      console.error("Error al actualizar la visualización en home:", error);
      Swal.fire("Error", "No se pudo actualizar la visualización.", "error");
    }
  };

  // Editar una obra
  const handleEditArtwork = (artwork) => {
    setTitle(artwork.title);
    setDescription(artwork.description);
    setMaterial(artwork.material || "");
    setYear(artwork.year || "");
    setImageUrl(artwork.image);
    setShowInHome(artwork.showInHome || false);
    setEditId(artwork.id);
  };

  // Resetear formulario
  const resetForm = () => {
    setTitle("");
    setDescription("");
    setMaterial("");
    setYear("");
    setImage(null);
    setImageUrl("");
    setShowInHome(true); 
    setEditId(null);
  };

  return (
    <div className="container">
      <h2 className="header">Gestión de Obras</h2>

      <div className="card p-4 my-4">
        <h4>{editId ? "Editar Obra" : "Agregar Nueva Obra"}</h4>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Título</label>
          <input
            type="text"
            id="title"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">Descripción</label>
          <textarea
            id="description"
            className="form-control"
            rows="3"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>

        {/* Nuevo campo: Material */}
        <div className="mb-3">
          <label htmlFor="material" className="form-label">Material</label>
          <select
            id="material"
            className="form-control"
            value={material}
            onChange={(e) => setMaterial(e.target.value)}
            required
          >
            <option value="">Seleccionar...</option>
            <option value="Piedra">Piedra</option>
            <option value="Madera">Madera</option>
            <option value="Metal">Metal</option>
            <option value="Metal y Madera">Metal y Madera</option>
            <option value="Metal y Piedra">Metal y Piedra</option>
            <option value="Madera y Piedra">Madera y Piedra</option>
            <option value="Pintura">Pintura</option>
            <option value="Yeso">Yeso</option>
          </select>
        </div>

        {/* Nuevo campo: Año */}
        <div className="mb-3">
          <label htmlFor="year" className="form-label">Año</label>
          <input
            type="text" 
            id="year"
            className="form-control"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="image" className="form-label">Imagen</label>
          <input
            type="file"
            id="image"
            className="form-control"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
          />
          {imageUrl && !image && <img src={imageUrl} alt="Vista previa" className="mt-3 preview-img" />}
        </div>
        <div className="form-check mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="showInHome"
            checked={showInHome}
            onChange={(e) => setShowInHome(e.target.checked)}
          />
          <label htmlFor="showInHome" className="form-check-label">Mostrar en Home</label>
        </div>
        <button className="btn btn-primary me-2" onClick={handleAddOrEditArtwork}>
          {editId ? "Actualizar Obra" : "Añadir Obra"}
        </button>
        <button className="btn btn-secondary" onClick={resetForm}>Cancelar</button>
      </div>

      <h3 className="mt-5">Lista de Obras</h3>
      <table className="table">
        <thead>
          <tr>
            <th>Título</th>
            <th>Descripción</th>
            <th>Material</th>
            <th>Año</th>
            <th>Imagen</th>
            <th>Mostrar en Home</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {artworks.map((artwork) => (
            <tr key={artwork.id}>
              <td>{artwork.title}</td>
              <td>{artwork.description}</td>
              <td>{artwork.material}</td>
              <td>{artwork.year}</td>
              <td>
                <img src={artwork.image} alt={artwork.title} className="table-img" />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={artwork.showInHome || false}
                  onChange={() => handleShowInHomeToggle(artwork.id, artwork.showInHome)}
                />
              </td>
              <td>
                <button className="btn btn-warning btn-sm me-2" onClick={() => handleEditArtwork(artwork)}>
                  Editar
                </button>
                <button className="btn btn-danger btn-sm" onClick={() => handleDeleteArtwork(artwork.id)}>
                  Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AddArtwork;
