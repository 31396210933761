import React from "react";
import "./Contact.css";

const catalogPdf = `${process.env.PUBLIC_URL}/pdf/Catalogocarinafabaro.pdf`;

const Contact = () => {
  return (
    <div className="contact">
      <h2 className="text-center">Contacto</h2>
      <div className="contact-content d-flex flex-column align-items-center mt-4">
        <div className="contact-details mb-4">
          <p>Email: <a href="mailto:crfabaro@gmail.com">crfabaro@gmail.com</a></p>
          <p>Teléfono: <a href="tel:+5493804649529">+54 9 380 4649529</a></p>
          <div className="buttons-container d-flex gap-3 justify-content-center mt-3">
            <a href={catalogPdf} download className="btn btn-primary">
              Descargar Catálogo
            </a>
          </div>
        </div>
      </div>

      {/* Biografía */}
      <div className="biography-section mt-5">
        <h2 className="text-center">Biografía</h2>
        <div className="biography-content d-flex flex-wrap align-items-center mt-4">
          <div className="biography-image-container text-center mb-3">
            <img
              src={`${process.env.PUBLIC_URL}/images/cf1.jpg`}
              alt="Carina Fabaro"
              className="biography-image"
            />
          </div>
          <div className="biography-text">
            <p>
              Carina Fabaro nació el 20 de agosto de 1971 en San Juan y desde los 18 años vive en la ciudad de La Rioja donde llevó a cabo su formación académica en el Instituto Prof. Alberto Crulcich, egresando como profesora de Artes Visuales, además cursó la Tecnicatura en Escultura en el mismo instituto.
            </p>
            <p>
              En 2008 obtuvo el título de Licenciada en Artes Visuales con Orientación en Escultura, en el IUNA de Buenos Aires. En 2023 logró la Diplomatura en Museología y Curaduría de la UCASAL Universidad Católica de Salta. Y en 2024 la Especialización en Gestión Cultural de la UNC Universidad Nacional de Córdoba. Se desempeña actualmente como escultora y docente.
            </p>
            <p>
              Como escultora, cuenta con obras de gran formato en espacios públicos en diferentes provincias argentinas como Chaco, Córdoba, Jujuy, Entre Ríos, Tucumán, La Pampa, Chubut, Tierra del Fuego y San Luis; y en diferentes países, como Chile, Brasil, México, España, Italia y Estados Unidos. Participó en exposiciones individuales y colectivas.
            </p>
            <p>
              Trabaja diversos materiales naturales y combinados como la madera, la piedra, además del metal, resina y materiales de deshecho, en composiciones casi siempre abstractas. Actualmente es docente en el I.S.F.D. Pedro I. de Castro Barros. Es disertante en temas relativos a Lenguajes Artísticos y sus posibilidades expresivas.
            </p>
            <p>
              En 2019 disertó en la Bienal de La Habana sobre su obra. Fue capacitadora en Diseños Artesanales para emprendedores e investigadora del INFD.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
