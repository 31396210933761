import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebaseConfig";
import { collection, onSnapshot } from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "./Home.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Home = () => {
  const [artworks, setArtworks] = useState([]);
  const [blogPosts, setBlogPosts] = useState([]);
  const [newArrivals, setNewArrivals] = useState([]);

  const [filterYear, setFilterYear] = useState("");
  const [filterMaterial, setFilterMaterial] = useState("");

  const sliderImages = [
    { src: process.env.PUBLIC_URL + "/images/gesta1.jpg", text: "Explora la belleza del arte contemporáneo." },
    { src: process.env.PUBLIC_URL + "/images/gesta2.jpg", text: "Cada escultura cuenta una historia única." },
    { src: process.env.PUBLIC_URL + "/images/pacha.jpg", text: "Sumérgete en el mundo de la creatividad." },
    { src: process.env.PUBLIC_URL + "/images/obra.jpg", text: "El arte es la música visual del alma." },
  ];

  useEffect(() => {
    const unsubscribeArtworks = onSnapshot(collection(db, "artworks"), (snapshot) => {
      const fetchedArtworks = snapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((artwork) => artwork.showInHome);
      setArtworks(fetchedArtworks);
    });

    const unsubscribeBlogPosts = onSnapshot(collection(db, "latestExhibition"), (snapshot) => {
      const fetchedPosts = snapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((post) => post.showInHome);
      setBlogPosts(fetchedPosts);
    });

    const unsubscribeNewArrivals = onSnapshot(collection(db, "newArrivals"), (snapshot) => {
      const fetchedNewArrivals = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNewArrivals(fetchedNewArrivals);
    });

    return () => {
      unsubscribeArtworks();
      unsubscribeBlogPosts();
      unsubscribeNewArrivals();
    };
  }, []);

  const uniqueYears = [...new Set(artworks.map((art) => art.year).filter(Boolean))];
  const uniqueMaterials = [...new Set(artworks.map((art) => art.material).filter(Boolean))];

  const filteredArtworks = artworks.filter((art) => {
    const matchYear = filterYear ? art.year === filterYear : true;
    const matchMaterial = filterMaterial ? art.material === filterMaterial : true;
    return matchYear && matchMaterial;
  });

  return (
    <div className="home">
      {/* Slider Section */}
      <section className="slider-section">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000 }}
          loop={true}
          className="slider-container"
        >
          {sliderImages.map((slide, index) => (
            <SwiperSlide key={index}>
              <div
                className="slider-item"
                style={{
                  backgroundImage: `url(${slide.src})`,
                }}
              >
                <div className="slider-text">
                  <p>{slide.text}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>

      <div className="container page-intro">
        <h1 className="main-title">Bienvenido a la Galería</h1>
      </div>

      <div className="main-content container">
        <section className="artwork-section">
          <h2 className="section-title">Obras Destacadas</h2>
          <div className="filters">
            <div className="filter-item">
              <label htmlFor="filterYear">Filtrar por Año:</label>
              <select
                id="filterYear"
                value={filterYear}
                onChange={(e) => setFilterYear(e.target.value)}
              >
                <option value="">Todos</option>
                {uniqueYears.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-item">
              <label htmlFor="filterMaterial">Filtrar por Material:</label>
              <select
                id="filterMaterial"
                value={filterMaterial}
                onChange={(e) => setFilterMaterial(e.target.value)}
              >
                <option value="">Todos</option>
                {uniqueMaterials.map((mat) => (
                  <option key={mat} value={mat}>
                    {mat}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            {filteredArtworks.length > 0 ? (
              filteredArtworks.map((artwork) => (
                <div className="col" key={artwork.id}>
                  <div className="artwork-card">
                    <div
                      className="artwork-image"
                      style={{
                        backgroundImage: `url(${artwork.image})`,
                      }}
                    ></div>
                    <div className="artwork-info">
                      <h3>{artwork.title}</h3>
                      <p>Material: {artwork.material}</p>
                      <p>Año: {artwork.year}</p>
                      <Link to={`/artwork/${artwork.id}`} className="read-more">
                        Leer más
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="no-artworks">No hay obras que coincidan con los filtros seleccionados.</p>
            )}
          </div>
        </section>


        <aside className="sidebar">
          <h2 className="sidebar-title">Lo Nuevo</h2>
          <div className="new-arrivals-grid">
            {newArrivals.length > 0 ? (
              newArrivals.map((item) => (
                <div key={item.id} className="new-arrival-card">
                  <Link to={`/new/${item.id}`}>
                    <img src={item.image} alt={item.title} className="new-arrival-image" />
                    <p className="new-arrival-title">{item.title}</p>
                  </Link>
                </div>
              ))
            ) : (
              <p>No hay novedades disponibles.</p>
            )}
          </div>
        </aside>
      </div>





        <section className="blog-section">
          <h2 className="section-title">Últimas Entradas del Blog</h2>
          <div className="blog-grid">
            {blogPosts.length > 0 ? (
              blogPosts.map((post) => (
                <div key={post.id} className="blog-card">
                  <div
                    className="blog-image"
                    style={{
                      backgroundImage: `url(${post.image})`,
                    }}
                  ></div>
                  <div className="blog-content">
                    <h3>{post.title}</h3>
                    <p>{post.subtitle}</p>
                    <Link to={`/blog/${post.id}`} className="read-more">
                      Leer más
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <p className="no-posts">No hay entradas disponibles.</p>
            )}
          </div>
        </section>

    
      <section className="video-section container">
        <h2 className="section-title">Explora Nuestro Arte</h2>
        <video controls className="promo-video">
          <source src={process.env.PUBLIC_URL + "/videos/promo.mp4"} type="video/mp4" />
          Tu navegador no soporta videos HTML5.
        </video>
      </section>
    </div>
  );
};

export default Home;
